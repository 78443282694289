// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import { Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import config from "src/config";
import FileSaver from "file-saver";

const download = (url: string, filename: string) =>
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      var file = new File([blob], filename, { type: "application/x-sqlite3" });
      FileSaver.saveAs(file);
    })
    .catch((error) => message.error(error.toString()));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MasterDownload: React.FC<{ size: SizeType; title?: string }> = ({ size, title }) => {
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        download(`${config.api}/settings/download`, "master.db");
      }}
      size={size}
      type="primary"
      icon={<DownloadOutlined />}
    >
      {" "}
      {title ? title : "Download"}
    </Button>
  );
};

export default MasterDownload;
