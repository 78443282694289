import { Space, Typography } from "antd";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import React, { } from "preact/compat";
import { SizeType } from "antd/es/config-provider/SizeContext";
import AddDeviceForm from "./AddDeviceForm";
import AddCustomerForm from "./AddCustomerForm";
// import useToken from "antd/es/theme/useToken";


const Apitest: React.FC<{ size: SizeType }> = ({ size }) => {

  return (
    <Space direction="vertical" style={{ width: "100%" }} className="gerl-customer-table">
      <Typography.Title level={4}>Kunde hinzufügen</Typography.Title>
      <AddCustomerForm size={size} />
      <Typography.Title level={4}>Gerät hinzufügen</Typography.Title>
      <AddDeviceForm size={size} />
    </Space>
  );
};

export default Apitest;
