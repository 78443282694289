import { Button, Divider, Form, Input, message, Select, Space, Typography } from "antd";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import React, { useEffect, useState } from "preact/compat";
import { SizeType } from "antd/es/config-provider/SizeContext";
// import useToken from "antd/es/theme/useToken";
import useFetch from "../use-fetch";
import config from "../config";

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const responseHandler = (r: Response) => {
  if (!r.ok || r.status >= 300) {
    throw new Error(r.statusText);
  }
  return r.json();
};

const addDevice = (customer: string, data: any) =>
  fetch(`${config.api}/api/customer/${customer}/devices`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
  })
    .then(responseHandler)
  ;



const removeUndefValues = (obj: any) => Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key])

const AddDeviceForm: React.FC<{ size: SizeType }> = ({ size }) => {
  const { data: customers, error: customerFetchError, loading: customerFetchLoading, fetchData: customerFetchData } = useFetch("/api/customers");
  const [customer, setCustomer] = useState()
  const [loading, setLoading] = useState(false)
  const { data: departments, error: departmentsFetchError, loading: departmentsFetchLoading, fetchData: departmentsFetchData } = useFetch(`/api/customer/${customer}/departments`);
  const { data: manufacturers, error: manufacturersFetchError, loading: manufacturersFetchLoading, fetchData: manufacturersFetchData } = useFetch("/api/manufacturers");
  const { data: tests, error: testsFetchError, loading: testsFetchLoading, fetchData: testsFetchData } = useFetch("/api/pruefablauf");

  useEffect(() => {
    const errors = [customerFetchError, departmentsFetchError].filter(Boolean)
    if (errors.length) {
      errors.forEach(err => message.error(err));
    }
  }, [customerFetchError, departmentsFetchError, manufacturersFetchError, testsFetchError]);


  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const { customer, department, manufacturer, test } = values
    const raum_name = department[0];
    const manufacturer_name = manufacturer[0];
    const pruefablauf_name = test;
    const device = {
      ...values,
      customer: undefined,
      department: undefined,
      manufacturer: undefined,
      test: undefined,
    }
    // We do not really need this as JSON.stringify in addDevice would do that also
    removeUndefValues(device)
    setLoading(true)
    addDevice(customer, {
      raum_name,
      manufacturer_name,
      pruefablauf_name,
      device
    })
      .then((result) => {
        message.success('OK')
        onReset()
      })
      .catch((error) => message.error(error.toString()))
      .finally(() => setLoading(false))
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFill = () => {
    form.setFieldsValue({
      customer: '200022',
      manufacturer: ['Dentsply Sirona'],
      department: ['Behandlung 1'],
      Geraete_Bezeichnung: 'TestGerät',
      Geraete_SN: 'sn123456',
      Geraete_ID: 'id123456',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      style={{}}
      disabled={loading}
    >
      <Form.Item name="customer" label="Kunde" rules={[{ required: true }]}>
        <Select
          placeholder="Kunde auswählen"
          onChange={(value) => setCustomer(value)}
          showSearch
        >
          {(customers ?? []).map(c => c["Kunden_Name"]).sort().map(name => <Option value={name}>{name}</Option>)}
        </Select>
      </Form.Item>

      <Form.Item name="department" label="Raum" rules={[{ required: true }]}>
        <Select
          placeholder="Raum wählen oder neu"
          mode="tags"
          maxTagCount={1}
          maxCount={1}
        >
          {(departments ?? []).map(c => c["name"]).sort().map(name => <Option value={name}>{name}</Option>)}
        </Select>
      </Form.Item>

      <Divider />

      <Form.Item name="Geraete_Bezeichnung" label="Bezeichnung" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="Geraete_Bemerkung" label="Bemerkung" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="manufacturer" label="Hersteller" rules={[{ required: true }]}>
        <Select
          placeholder="Hersteller wählen oder neu"
          mode="tags"
          maxTagCount={1}
          maxCount={1}
        >
          {(manufacturers ?? []).map(c => c["name"]).sort().map(name => <Option value={name}>{name}</Option>)}
        </Select>
      </Form.Item>

      <Form.Item name="Geraete_Typ" label="Typ" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="Geraete_Modell" label="Modell" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="Geraete_SN" label="Serial" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="Geraete_ID" label="ID" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="Gebaeude" label="Gebäude" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="test" label="Prüfablauf" rules={[]}>
        <Select
          placeholder="Prüfablauf wählen"
          allowClear
        >
          {(tests ?? []).map(c => c["Pruefung_Name"]).sort().map(name => <Option value={name}>{name}</Option>)}
        </Select>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
          <Button type="link" htmlType="button" onClick={onFill}>
            Fill form
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AddDeviceForm;
