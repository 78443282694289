import { Button, Form, Input, message, Select, Space } from "antd";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import React, { useState } from "preact/compat";
import { SizeType } from "antd/es/config-provider/SizeContext";
// import useToken from "antd/es/theme/useToken";
import config from "../config";

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const responseHandler = (r: Response) => {
  if (!r.ok || r.status >= 300) {
    throw new Error(r.statusText);
  }
  return r.json();
};

const addCustomer = (data: any) =>
  fetch(`${config.api}/api/customers`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
  })
    .then(responseHandler)
  ;



const removeUndefValues = (obj: any) => Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key])

const AddDeviceForm: React.FC<{ size: SizeType }> = ({ size }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const { customer } = values
    const data = {
      ...values,
      Kunden_Name: customer,
      Kunden_Nummer: customer,
      customer: undefined
    }
    setLoading(true)
    addCustomer(data)
      .then((result) => {
        message.success('OK')
        onReset()
      })
      .catch((error) => message.error(error.toString()))
      .finally(() => setLoading(false))
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFill = () => {
    form.setFieldsValue({
      customer: '999999',
      Kunden_Strasse: 'Teststr. 12',
      Kunden_PLZ: '99999',
      Kunden_Wohnort: 'Teststadt',
      Kunden_Rufnummer: '0123456789',
      Kunden_Email: 'no-email',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      style={{}}
      disabled={loading}
    >
      <Form.Item name="customer" label="Name/Nummer" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="Kunden_Strasse" label="Straße" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="Kunden_PLZ" label="PLZ" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="Kunden_Wohnort" label="Ort" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="Kunden_Rufnummer" label="Rufnummer" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="Kunden_Bemerkung" label="Bemerkung" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="Kunden_Fax" label="Fax" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="Kunden_Email" label="Email" rules={[{ type: 'email' }]}>
        <Input />
      </Form.Item>

      <Form.Item name="Kunden_Ansprechpartner" label="Ansprechpartner" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="Ansprechpartner_Abteilung" label="Ansprechpartner-Abteilung" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="Ansprechpartner_Rufnummer" label="Ansprechpartner-Rufnummer" rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item name="Ansprechpartner_Email" label="Ansprechpartner-Email" rules={[{ type: 'email' }]}>
        <Input />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
          <Button type="link" htmlType="button" onClick={onFill}>
            Fill form
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AddDeviceForm;
