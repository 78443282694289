import { useEffect, useState } from "preact/hooks";
import config from "./config";

const useFetch = (url: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[]>();
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const responseHandler = (r: Response) => {
    if (!r.ok || r.status >= 300) {
      throw new Error(r.statusText);
    }
    return r.json();
  };
  const errorHandler = (err: unknown) => setError(err?.toString() ?? "Unbekannter Fehler");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchData = (opts?: any) => {
    setError(null);
    setLoading(true);

    fetch(`${config.api}${/^\//.test(url) ? "" : "/"}${url}`, { ...opts })
      .then(responseHandler)
      .then(setData)
      .catch(errorHandler)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const myAbortController = new AbortController();

    fetchData({ signal: myAbortController.signal });

    return () => myAbortController.abort();
  }, [url]);

  return { data, error, loading, fetchData };
};

export default useFetch