// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import { Button, Upload, UploadProps, message } from "antd";
import {
  UploadOutlined,
  SyncOutlined,
  WarningOutlined,
  QuestionCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import config from "src/config";
import { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import useToken from "antd/es/theme/useToken";

const props: UploadProps = {
  name: "file",
  action: `${config.api}/merge/upload`,
  // headers: {
  //   "x-auth-user":
  //     "eyJAb2RhdGEuY29udGV4dCI6Imh0dHBzOi8vZ3JhcGgubWljcm9zb2Z0LmNvbS92MS4wLyRtZXRhZGF0YSN1c2Vycy8kZW50aXR5IiwiYnVzaW5lc3NQaG9uZXMiOltdLCJkaXNwbGF5TmFtZSI6IlNjaGxlZ2VsLCBHZXJoYXJkIiwiZ2l2ZW5OYW1lIjoiR2VyaGFyZCIsImpvYlRpdGxlIjpudWxsLCJtYWlsIjoiRy5TY2hsZWdlbEBnZXJsLWRlbnRhbC5kZSIsIm1vYmlsZVBob25lIjoiKzQ5IDE3MjU0Njk4ODkiLCJvZmZpY2VMb2NhdGlvbiI6IlplbnRyYWxlIiwicHJlZmVycmVkTGFuZ3VhZ2UiOm51bGwsInN1cm5hbWUiOiJTY2hsZWdlbCIsInVzZXJQcmluY2lwYWxOYW1lIjoiRy5TY2hsZWdlbEBnZXJsLWRlbnRhbC5kZSIsImlkIjoieHh4In0=",
  // },
};

const MergeForm: React.FC<{
  size: SizeType;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMerged?: (fileInfo: UploadChangeParam<UploadFile<any>>) => void;
}> = ({ size, title, onMerged }) => {
  const designToken = useToken()[1];

  return (
    <Upload
      {...props}
      onChange={(info) => {
        if (info.file.status !== "uploading") {
          console.log(info);
        }
        if (info.file.status === "done") {
          message.success(`${info.file.name} merged successfully`);
          if (onMerged) {
            onMerged(info);
          }
        } else if (info.file.status === "error") {
          message.error(`${info.file.name}: Merge failed (${info.file.response || "unknown error"})`);
        }
      }}
      showUploadList={{
        extra: ({ size = 0 }) => <span style={{ color: "#cccccc" }}> ({(size / 1024 / 1024).toFixed(2)}MB)</span>,
        showDownloadIcon: false,
        showRemoveIcon: false,
      }}
      iconRender={(file) => {
        switch (file.status) {
          case "uploading":
            return <SyncOutlined spin />;
          case "done":
            return <CheckCircleTwoTone twoToneColor={designToken.colorSuccess} />;
          case "error":
            return <WarningOutlined />;
          default:
            return <QuestionCircleOutlined />;
        }
      }}
    >
      <Button size={size} type="primary" icon={<UploadOutlined />}>
        {" "}
        {title ? title : "Merge"}
      </Button>
    </Upload>
  );
};

export default MergeForm;
