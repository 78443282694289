// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import { Button, Upload, UploadProps, message } from "antd";
import {
  UploadOutlined,
  SyncOutlined,
  WarningOutlined,
  QuestionCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import config from "src/config";
import { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import useToken from "antd/es/theme/useToken";

const props: UploadProps = {
  name: "file",
  action: `${config.api}/settings/upload`,
  // headers: {
  //   authorization: 'authorization-text',
  // },
};

const MasterUpload: React.FC<{
  size: SizeType;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdated?: (fileInfo: UploadChangeParam<UploadFile<any>>) => void;
}> = ({ size, title, onUpdated }) => {
  const designToken = useToken()[1];

  return (
    <Upload
      {...props}
      onChange={(info) => {
        if (info.file.status !== "uploading") {
          console.log(info);
        }
        if (info.file.status === "done") {
          message.success(`${info.file.name} now serves as master database`);
          if (onUpdated) {
            onUpdated(info);
          }
        } else if (info.file.status === "error") {
          message.error(
            `${info.file.name}: Could not update master database (${info.file.response || "unknown error"})`,
          );
        }
      }}
      showUploadList={{
        extra: ({ size = 0 }) => <span style={{ color: "#cccccc" }}>({(size / 1024 / 1024).toFixed(2)}MB)</span>,
        showDownloadIcon: false,
        showRemoveIcon: false,
      }}
      iconRender={(file) => {
        switch (file.status) {
          case "uploading":
            return <SyncOutlined spin />;
          case "done":
            return <CheckCircleTwoTone twoToneColor={designToken.colorSuccess} />;
          case "error":
            return <WarningOutlined />;
          default:
            return <QuestionCircleOutlined />;
        }
      }}
    >
      <Button size={size} type="primary" icon={<UploadOutlined />}>
        {" "}
        {title ? title : "Update"}
      </Button>
    </Upload>
  );
};

export default MasterUpload;
