import { StyleProvider } from "@ant-design/cssinjs";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import { ConfigProvider, Space } from "antd";
import { useEffect, useRef, useState } from "preact/hooks";
import { SizeType } from "antd/es/config-provider/SizeContext";
import Router, { CustomHistory, Link, route } from "preact-router";
import { createHashHistory, createMemoryHistory } from "history";
import useToken from "antd/es/theme/useToken";
import CustomerTable from "./components/CustomerTable.tsx";
import History from "./components/History.tsx";
import Apitest from "./components/Apitest.tsx";

const App = ({ token, size = "large", routing = "on" }: { token: string; size?: SizeType; routing?: "on" | "off" }) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [stylesRoot, setStylesRoot] = useState<HTMLElement>();

  // Howto use theme in js:
  const designToken = useToken()[1];
  const padding = designToken?.padding ?? 12;

  useEffect(() => {
    if (rootRef.current) {
      setStylesRoot(rootRef.current.getRootNode() as HTMLElement);
    }
  }, [rootRef]);

  const updateDta = () => {
    return () => { };
  };

  useEffect(updateDta, [token]);

  return (
    <div ref={rootRef}>
      {stylesRoot && (
        <StyleProvider container={stylesRoot as Element}>
          <ConfigProvider getPopupContainer={() => stylesRoot} theme={{ token: { colorPrimary: "#183146" } }}>
            <Space style={{ padding, paddingLeft: 0 }}>
              <Link
                onClick={() => {
                  route("/");
                }}
              >
                Home{" "}
              </Link>
              <Link
                onClick={() => {
                  route("/settings");
                }}
              >
                Settings
              </Link>
              <Link
                onClick={() => {
                  route("/apitest");
                }}
              >
                API-Test
              </Link>
            </Space>
            <Router
              history={(routing == "on" ? createHashHistory() : createMemoryHistory()) as unknown as CustomHistory}
            >
              <div path="/">
                <CustomerTable size={size} />
              </div>
              <div path="/settings">
                <History size={size} />
              </div>
              <div path="/apitest">
                <Apitest size={size} />
              </div>
            </Router>
          </ConfigProvider>
        </StyleProvider>
      )}
    </div>
  );
};

export default App;
